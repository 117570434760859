import { Question } from '../types'

export const questions: Question[] = [
  {
    key: '1',
    title: 'Что такое Твой Рынок?',
    content: `ТвойРынок – это удобный сервис для доставки свежих натуральных продуктов с рынков вашего города. Вам доступен удобный каталог для выбора товаров. Мы соберем ваш заказ и упакуем так, чтобы продукты не испортились при транспортировке. Вы сможете выбрать самовывоз или курьерскую доставку с указанием времени и адреса.`,
  },
  {
    key: '2',
    title: 'Личный кабинет',
    content: `После скачивания приложения вы пройдете минутную регистрацию, указав имя и контактные данные. Мы сохраним историю ваших заказов, на случай, если вы захотите их повторить.`,
  },
  {
    key: '3',
    title: 'Сборка заказа',
    content: `Вы можете складывать в корзину товары с одного прилавка или сделать набор из ассортимента разных продавцов. Все продукты, оформленные в один заказ, будут собраны вместе и доставлены единовременно. Наши сотрудники проследят, чтобы товары были свежие и качественные. В случае, когда необходимо будет произвести замену продукта, мы позвоним вам и уточним все нюансы.`,
  },
  {
    key: '4',
    title: 'Упаковка',
    content: `Мы внимательно следим за тем, чтобы продукты не повредились во время доставки и попали на ваш стол свежими. Аккуратно упаковываем каждый заказ, соблюдая принципы товарного соседства. `,
  },
  {
    key: '5',
    title: 'Самовывоз',
    content: `Для оформления самовывоза вам нужно будет выбрать этот способ получения при оформлении заказа. Если вы оформили заказ, когда рынок уже закрыт, он будет собран и готов к выдаче следующим утром. Если время заказа и самовывоза укладывается в режим работы рынка, то продукты можно будет забрать в этот же день. Для вашего удобства на рынках есть навигация по указателям «Твой Рынок», которые приведут к нашим сотрудникам.`,
  },
  {
    key: '6',
    title: 'Условия доставки',
    content: `Доставка товара сервиса ТвойРынок.Онлайн доступна во время работы вашего рынка. Вы можете оформить последний заказ с доставкой сегодня за час до закрытия рынка. Доставка возможна в пределах города, в котором расположен рынок. Расстояние от рынка до места доставки будет влиять на стоимость. Пожалуйста, внимательно ознакомьтесь с информацией, представленной ниже. `,
  },
  {
    key: '7',
    title: 'Стоимость доставки',
    content: 'Стоимость первой доставки — всего 49 рублей! Стоимость любой следующей доставки — 79 рублей',
  },
  {
    key: '8',
    title: 'Стоимость заказа',
    content: `Стоимость заказа формируется из стоимости товаров и стоимости доставки.`,
  },
]
